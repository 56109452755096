import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dcea9e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "routeChild",
  ref: "routeChild"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyMenu = _resolveComponent("MyMenu")!
  const _component_MyHeader = _resolveComponent("MyHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_back_top = _resolveComponent("a-back-top")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { style: {"height":"100vh"} }, {
    default: _withCtx(() => [
      _createVNode(_component_MyMenu),
      _createVNode(_component_a_layout, { style: {"height":"100vh"} }, {
        default: _withCtx(() => [
          _createVNode(_component_MyHeader),
          _createVNode(_component_a_layout_content, { class: "mainContent" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_router_view, { class: "trueView" }),
                _createVNode(_component_a_back_top, {
                  target: ()=>_ctx.routeChild
                }, null, 8, ["target"])
              ], 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}