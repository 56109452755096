import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45e665ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "closeDropdown" }
const _hoisted_3 = { style: {"margin-right":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_PoweroffOutlined = _resolveComponent("PoweroffOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "myHeader" }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.route.meta.title), 1),
      _createVNode(_component_a_dropdown, {
        trigger: ['click'],
        placement: "bottomRight",
        visible: _ctx.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event))
      }, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, { class: "headerMenu" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, { key: "1" }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
                    class: "dropView"
                  }, [
                    _createVNode(_component_PoweroffOutlined, { style: {"margin-right":"10px"} }),
                    _createTextVNode(" 注销 ")
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_UserOutlined, { style: {"margin-right":"10px"} }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.userName), 1),
              _createVNode(_component_CaretDownOutlined, { style: {"margin-left":"5px"} })
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }))
}