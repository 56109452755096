
import {
  ControlOutlined,
  DesktopOutlined,
  ProjectOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  CarryOutOutlined,
} from '@ant-design/icons-vue';
import {defineComponent, ref} from "vue";
import {useRouter, useRoute, onBeforeRouteUpdate} from 'vue-router'
import { createFromIconfontCN } from '@ant-design/icons-vue';

const IconFont = createFromIconfontCN({
  scriptUrl: './font_2837090_06hrcdhv39cc.js',
});

export default defineComponent({
  components: {
    ControlOutlined,
    DesktopOutlined,
    ProjectOutlined,
    IdcardOutlined,
    DatabaseOutlined,
    CarryOutOutlined,
    IconFont
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let selectedKeys = ref<string[]>([route.path])

    const selectMenu = ({item, key, selectedKeys}: any) => {
      if (key.indexOf("/") != -1)
        // router.push(key)
        // router.push({path:key,query:{time:new Date().toTimeString()}})
        router.push({name:key,params:{time:new Date().toTimeString()}})
    };
    onBeforeRouteUpdate(to => {
      if (to.path === "/configurationView") {
        selectedKeys.value = []
      }else{
        selectedKeys.value = [to.path]
      }
    })
    return {
      collapsed: ref<boolean>(false),
      selectedKeys,
      selectMenu,
    }
  }
})
