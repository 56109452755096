
import {defineComponent, watch, ref} from 'vue';
import {CaretDownOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons-vue"
import {useRouter, useRoute} from 'vue-router'

export default defineComponent({
  name: "MyHeader",
  setup() {
    let visible = ref<boolean>(false);
    const router = useRouter()
    const route = useRoute()
    const toUserInfo = () => {
      // router.push("/userInfo")
      visible.value = false
    }
    const logout = () => {
      sessionStorage.removeItem("userId")
      sessionStorage.removeItem("userName")
      sessionStorage.removeItem("X2ServerID")
      sessionStorage.removeItem("Token")
      sessionStorage.removeItem("Host")
      router.push("/login")
    }
    watch(visible, () => {
      //下拉动画有延迟，同步自己做的颜色
      if (visible.value) {
        document.getElementsByClassName("closeDropdown")[0].className = "openDropdown"
      } else {
        setTimeout(() => {
          document.getElementsByClassName("openDropdown")[0].className = "closeDropdown"
        }, 150)
      }
    })

    return {
      toUserInfo,
      logout,
      visible,
      route,
      userName: sessionStorage.getItem("userName")
    }
  },
  components: {
    CaretDownOutlined,
    PoweroffOutlined,
    UserOutlined,
  }
})
