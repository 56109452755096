
import {defineComponent, ref} from "vue";
import MyHeader from "./MyHeader.vue"
import MyMenu from "./MyMenu.vue"

export default defineComponent({
  components: {
    MyHeader,
    MyMenu
  },
  setup() {
    const routeChild = ref();
    return {
      routeChild
    };
  }
})
